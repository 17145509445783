const appLayout = {
    name: 'appLayout',
    padding: 0,
    panels: [
        { type: 'top', size: 40 },
        { type: 'right', size: 500, resizable: true, hidden: true,
            toolbar: {
                style: 'height: 52px;',
            },
            html: '<div class="w2ui-centered" style="color: gray;">Click the "Generate" button to build lineups</div>'
        },
        { type: 'main', /* overflow: 'hidden' */ }
    ]
};

export default appLayout;