const opposingTeamRestrictionsGrid = {
    name: 'opposingTeamRestrictionsGrid',
    columns: [],
    records: [],
    show: {
        toolbar: true,
        toolbarSearch: false,
        toolbarReload: false,
    },
    toolbar: {
        name: 'opposingTeamRestrictionsGridToolbar',
        items: [
            { type: 'button', id: 'clearAll', text: 'Clear All', icon: 'fa fa-table' },
        ],
        onClick: (event) => {
            switch(event.detail.item.id) {
                case 'clearAll':
                    event.owner.owner.records.map(rec => {
                        // eslint-disable-next-line no-unused-vars
                        const { recid, w2ui, variable, ...newObj } = rec;
                        Object.keys(newObj).map(pos => { rec[pos] = false; });
                    });
                    event.owner.owner.refresh();
                    break;
                default:
                    console.log('Invalid Toolbar Item Selected');
            }
        },
    },
    onChange: (event) => {
        event.onComplete = function () {
            event.owner.save();
        };
    },
};

export default opposingTeamRestrictionsGrid;