import Lineups from '../components/lineups.js';

const appToolbar = {
    name: 'appToolbar',
    tooltip: 'top',
    items: [
        { type: 'menu', id: 'sport',  items: [] },
        { type: 'menu-radio', id: 'slate', items: [] },
        { type: 'break' },
        { type: 'radio', id: 'players', text: 'Players', icon: 'fa fa-group', checked: true,
            onClick: () => {
                window.app.w2ui.appLayout.html('main', window.app.w2ui.playersGrid);
            } 
        },
        { type: 'radio', id: 'settings', text: 'Settings', icon: 'fa fa-cog',
            onClick: () => {
                window.app.w2ui.appLayout.html('main', window.app.w2ui.settingsLayout);
            } 
        },
        { type: 'break' },
        { type: 'button', id: 'lineups', text: 'Lineups', icon: 'fa fa-list-alt',
            onClick: () => {
                window.app.w2ui.appLayout.toggle('right');
                // const lineups = new Lineups({ name: 'hello', data: 'THIS is DATA!!!'});
                // console.log(typeof lineups);
                // // lineups.render('#app-lineups');
                // window.app.w2ui.appLayout.html('main', lineups);
                // // window.app.w2ui.appLayout.refresh();
            } 
        },
        { type: 'spacer' },
        { type: 'input', id: 'numberPicks', text: 'Picks', value: 6,
            input: {
                spinner: true,
                style: 'text-align: center; width: 85px',
                min: 2,
                max: 6,
                step: 1
            }
        },
        { type: 'break' },
        { type: 'input', id: 'numberLineups', text: 'Lineups', value: 10,
            input: {
                spinner: true,
                style: 'text-align: center; width: 85px',
                min: 1,
                max: 150,
                step: 5
            }
        },
        // { type: 'break' },
        // { type: 'input', id: 'randomness', text: 'Randomness', tooltip: 'Percent randomness to adjust projections', value: 5,
        //     input: {
        //         style: 'width: 120px',
        //         attrs: 'type="range" min="0" max="100" step="5"'
        //     },
        // },
        { type: 'break' },
        { type: 'html', id: 'generate',
            async onRefresh(event) {
                await event.complete;
                const fragment = window.query.html(`<button class="w2ui-btn w2ui-btn-blue">Generate</button>`);
                fragment.on('click', () => {
                    const players = window.app.w2ui.playersGrid.records
                        .map(t1 => ({...t1, ...window.app.w2ui.playersGrid.getChanges().find(t2 => t2.recid === t1.recid)}))
                        .flatMap(item => [
                            {
                            ...item,
                            include: item.includeOver,
                            projection: item.over,
                            type: 'over',
                            rosterPosition: `${item.statAbbreviation}-O`
                            },
                            {
                            ...item,
                            include: item.includeUnder,
                            projection: item.under,
                            type: 'under',
                            rosterPosition: `${item.statAbbreviation}-U`
                            }
                        ]);
                    const sameTeamRestrictions = window.app.w2ui.sameTeamRestrictionsGrid.records.flatMap(rec => {
                        // eslint-disable-next-line no-unused-vars
                        const { recid, w2ui, variable, ...newObj } = rec;
                        return Object.keys(newObj).filter(pos => rec[pos]).map(pos => ({[rec.recid]: pos}));
                    });
                    const opposingTeamRestrictions = window.app.w2ui.opposingTeamRestrictionsGrid.records.flatMap(rec => {
                        // eslint-disable-next-line no-unused-vars
                        const { recid, w2ui, variable, ...newObj } = rec;
                        return Object.keys(newObj).filter(pos => rec[pos]).map(pos => ({[rec.recid]: pos}));
                    });
                    const payload = {
                        players,
                        // eslint-disable-next-line no-undef
                        positions: [...new Set(players.map(player => player.rosterPosition))],
                        numberOfPicks: window.app.w2ui.appToolbar.get('numberPicks').value,
                        numberOfLineups: window.app.w2ui.appToolbar.get('numberLineups').value,
                        sameTeamRestrictions,
                        opposingTeamRestrictions,
                        rules: window.app.w2ui.rulesForm.record,
                    };
                    window.app.w2ui.appLayout.lock('main', 'Generating Lineups...', true);
                    fetch('./build/', {
                        method: 'POST',
                        mode: 'cors',
                        cache: 'no-cache',
                        credentials: 'same-origin',
                        headers: {
                          'Content-Type': 'application/json'
                        },
                        redirect: 'follow',
                        referrerPolicy: 'no-referrer',
                        body: JSON.stringify(payload)
                      })
                    .then(response => response.json())
                    .then(data => {
                        window.app.w2ui.appLayout.unlock('main');
                        if (data.status == 'error') {
                            window.app.w2ui.appToolbar.click('players');
                            window.app.w2ui.appLayout.message('main', data.message);
                            return;
                        }
                        const lineups = data.map(lineup => lineup.map(lineupPlayer => ({
                                pick: lineupPlayer.pick,
                                points: lineupPlayer.points,
                                ...window.app.w2ui.playersGrid.records.find(rec => rec.playerId == lineupPlayer.id && rec.statAbbreviation == lineupPlayer.statAbbreviation)
                            })
                        ));
                        // window.app.w2ui.builds_layout.get('top').tabs.addNewBuild(new Date().getTime(), new Date().toLocaleTimeString(), lineups);
                        window.app.w2ui.appLayout.show('right');
                        window.app.w2ui.appLayout.html('right', new Lineups({ name: Date.now(), data: lineups}));
                        // window.app.w2ui.appLayout.refresh();
                    });
                });
                window.query(this.box).find('#tb_appToolbar_item_generate').append(fragment);
            } 
        }

    ],
    onClick: function (event) {
        if (!event.detail.subItem) return;
        switch (event.detail.item.id) {
            case 'sport':
                window.location.href = `/${event.detail.subItem.id}/`;
                break;
            case 'slate':
                window.location.href = '/' + window.app.w2ui.appToolbar.get('sport').selected + '/' + event.detail.subItem.id + '/';
                break;
        }
    }
};

export default appToolbar;