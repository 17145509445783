const rulesForm = {
    name: 'rulesForm',
    focus: -1,
    fields: {
        'Lineup Rules': {
            type: 'group', span: -1,
            fields: [
                {
                    field: 'uniquePlayers',
                    type: 'int',
                    html: { label: 'Unique Players' },
                    options: { min: 2, max: 6, step: 1, arrows: true },
                },
            ]
        },
        'Player Rules': {
            type: 'group', span: -1,
            fields: [
                {
                    field: 'maxPlayerExposure',
                    type: 'percent',
                    html: { label: 'Max Exposure' },
                    options: { min: 1, max: 100, step: 5, arrows: true },
                },
            ]
        },
        'Optimizer Rules': {
            type: 'group', span: -1,
            fields: [
                { field: 'evCalcVariable', type: 'select', required: false,
                    html: { label: 'EV Calculation Variable' },
                    options: { items: ['Min Payout', 'Avg Est Payout', 'High Est Payout'] }
                }
            ]
        }
    },
    record: {
        evCalcVariable: 'High Est Payout',
    },
    actions: {
        'Reset'() {
            this.clear();
        }
    }
};

export default rulesForm;