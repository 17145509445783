const playersGrid = {
    name: 'playersGrid',
    method: 'GET',
    columnTooltip: 'right|left',
    recid: 'id',
    show: {
        footer: true,
        toolbarReload: false,
        toolbar: true,
        toolbarSearch: true,
        toolbarColumns: true,
    },
    liveSearch: true,
    multiSearch: true,
    markSearch: false,
    searches: [
        { field: 'playerName', label: 'Player Name', type: 'text', operator: 'contains', operators: ['contains'] },
    ],
    style: 'border: 0',
    columns: [
        { field: 'order', text: '<div style="text-align: center;">Bat</div>', max: '30', tooltip: 'Batting Order', sortable: true, sortMode: 'natural', style: 'text-align: center', hidden: true, hideable: false },
        { field: 'playerId', text: 'ID', hidden: true, hideable: false },
        {
            field: 'playerName', text: 'Name', sortable: true, min: '250', hideable: false,
            render: function (record) {
                var attributes = '';
                if (record.status) attributes += '<span style="color: red;"> ' + record.status + '</span>';
                return record.playerName + attributes;
            }
        },
        { field: 'stat', text: '<div style="text-align: center;">Stat</div>', min: '120', sortable: true, style: 'text-align: center', hideable: false },
        { field: 'line', text: '<div style="text-align: center;">Line</div>', min: '70', sortable: true, style: 'text-align: center', hideable: false, editable: { type: 'float' }, sortMode: 'natural', tooltip: 'Prop Line - Sportsbook line in parentheses if different than prop line' },
        { field: 'team', text: '<div style="text-align: center;">Team</div>', min: '60', sortable: true, style: 'text-align: center', hidden: true },
        { field: 'game', text: '<div style="text-align: center;">Game</div>', min: '120', sortable: true, style: 'text-align: center',
            render: (record) => {
                return record.game.replace(record.team, `<b>${record.team}</b>`);
            }
        },
        { field: 'startTime', text: '<div style="text-align: center;">Start</div>', min: '120', sortable: true, type: 'date', style: 'text-align: center;', hidden: true, render: (rec) => {
                return new Date(rec.startTime).toLocaleTimeString('en-us', {  weekday: 'short', hour: 'numeric', minute: '2-digit' });
            }
        },
        { field: 'position', text: '<div style="text-align: center;">Position</div>', min: '60', sortable: true, style: 'text-align: center', hidden: true },
        {
            field: 'includeOver', style: 'text-align: center', max: '30',
            editable: { type: 'checkbox', style: 'text-align: center' },
            text: `<div style="text-align: center;"><input title="Toggle Include Player" type="checkbox" checked onClick="window.app.w2ui.playersGrid.toggleInclude('over', this.checked)"/></div>`,
            tooltip: 'Include/Exclude Player Stat Over'
        },
        { field: 'over', text: '<div style="text-align: center;">Over</div>', min: '70', sortable: true, style: 'text-align: center', hideable: false, editable: { type: 'float' }, sortMode: 'natural', tooltip: 'DraftKings sportsbook over implied probability' },
        {
            field: 'includeUnder', style: 'text-align: center', max: '30',
            editable: { type: 'checkbox', style: 'text-align: center' },
            text: `<div style="text-align: center;"><input title="Toggle Include Player" type="checkbox" checked onClick="window.app.w2ui.playersGrid.toggleInclude('under', this.checked)"/></div>`,
            tooltip: 'Include/Exclude Player Stat Over'
        },
        { field: 'under', text: '<div style="text-align: center;">Under</div>', min: '70', sortable: true, style: 'text-align: center', hideable: false, editable: { type: 'float' }, sortMode: 'natural', tooltip: 'DraftKings sportsbook under implied probability' },
    ],
    toggleInclude: (side, include) => {
        window.app.w2ui.playersGrid.selectAll();
        window.app.w2ui.playersGrid.get(window.app.w2ui.playersGrid.getSelection()).map(player => {
            side == 'over' ? player.includeOver = include : player.includeUnder = include;
            window.app.w2ui.playersGrid.set(player.id, player, false);
        });
        window.app.w2ui.playersGrid.selectNone();
    },
    onChange: (event) => {
        event.onComplete = function () {
            event.owner.save();
        };
    },
    onRender: (event) => {
        event.done(() => {
            event.owner.status(`Last Update: ${new Date(window.initialData.updated).toLocaleTimeString('en-us', {  weekday: 'short', hour: 'numeric', minute: '2-digit' })}`);
        });
    }
};

export default playersGrid;