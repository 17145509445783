const settingsLayout = {
    name: 'settingsLayout',
    padding: 0,
    panels: [
        { type: 'main', overflow: 'hidden',
            style: 'background-color: white; border: 1px solid #efefef; border-top: 0px; border-left: 0; padding: 10px;',
            tabs: {
                active: 'rules',
                tabs: [
                    { id: 'rules', text: 'Rules' },
                    { id: 'opposingTeamRestrictions', text: 'Opposing Team Restrictions' },
                    { id: 'sameTeamRestrictions', text: 'Same Team Restrictions' },
                ],
                onClick(event) {
                    switch(event.target) {
                        case 'rules':
                            window.app.w2ui.settingsLayout.html('main', window.app.w2ui.rulesForm);
                            break;
                        case 'sameTeamRestrictions':
                            window.app.w2ui.settingsLayout.html('main', window.app.w2ui.sameTeamRestrictionsGrid);
                            break;
                        case 'opposingTeamRestrictions':
                            window.app.w2ui.settingsLayout.html('main', window.app.w2ui.opposingTeamRestrictionsGrid);
                            break;
                        default:
                            window.app.w2ui.settingsLayout.html('main', 'Invalid Tab Selected');
                    }
                }
            }
        }
    ]
};

export default settingsLayout;