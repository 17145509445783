import "../libs/w2ui/w2ui.min.css";
import "../styles/app.css";
import { w2ui, query, w2toolbar, w2layout, w2grid, w2form } from '../libs/w2ui/w2ui.es6.min.js';
import appLayout from '../components/appLayout';
import appToolbar from '../components/appToolbar';
import playersGrid from '../components/playersGrid.js';
import settingsLayout from '../components/settingsLayout';
import rulesForm  from '../components/rulesForm';
import sameTeamRestrictionsGrid from '../components/sameTeamRestrictionsGrid.js';
import opposingTeamRestrictionsGrid from '../components/opposingTeamRestrictionsGrid.js';

// register app globally
window.app = { w2ui };
window.query = query;

const layout = new w2layout(appLayout);
const toolbar = new w2toolbar(appToolbar);
const players = new w2grid(playersGrid);

const settingsLayoutObj = new w2layout(settingsLayout);
const rules = new w2form(rulesForm);
const sameTeamRestriction = new w2grid(sameTeamRestrictionsGrid);
const opposingTeamRestriction = new w2grid(opposingTeamRestrictionsGrid);
settingsLayoutObj.html('main', rules);

// eslint-disable-next-line no-undef
const uniqueStats = [...new Set([].concat(...window.initialData.players.map((player) => player.stat)))];
if (uniqueStats.length > 1 && uniqueStats.length != window.initialData.players.length) {
    players.addSearch(
        { field: 'stat', label: 'Stat', type: 'enum', options: { items: uniqueStats } 
    });
}
// eslint-disable-next-line no-undef
const uniquePositions = [...new Set([].concat(...window.initialData.players.map((player) => player.position.split('/'))))];
if (uniquePositions.length > 1) {
    // players.showColumn('position');
    players.addSearch(
        { field: 'position', label: 'Position', type: 'enum', options: { items: uniquePositions } 
    });
}
// eslint-disable-next-line no-undef
const uniqueTeams = [...new Set([].concat(...window.initialData.players.map((player) => player.team)))];
if (uniqueTeams.length > 1 && uniqueTeams.length != window.initialData.players.length) {
    // players.showColumn('team');
    players.addSearch(
        { field: 'team', label: 'Team', type: 'enum', options: { items: uniqueTeams } 
    });
}
// eslint-disable-next-line no-undef
const uniqueOpponents = [...new Set([].concat(...window.initialData.players.map((player) => player.opponent)))];
if (uniqueOpponents.length > 1) {
    players.showColumn('opponent');
}
// eslint-disable-next-line no-undef
const uniqueGames = [...new Set([].concat(...window.initialData.players.map((player) => player.game)))];
if (uniqueGames.length > 1) {
    if (uniqueTeams.length == window.initialData.players.length) players.showColumn('game');
    players.addSearch(
        { field: 'game', simple: false, label: 'Game', type: 'enum', options: { items: uniqueGames } 
    });
}
// eslint-disable-next-line no-undef
const uniqueStartTimes = [...new Set([].concat(...window.initialData.players.map((player) => player.startTime)))];
if (uniqueStartTimes.length > 1) players.addSearch(
    { field: 'startTime', simple: false, label: 'Start Time', type: 'enum', options: { 
        items: uniqueStartTimes.map(time => ({
            id: time,
            text: new Date(time).toLocaleTimeString('en-us', {  weekday: 'short', hour: 'numeric', minute: '2-digit' })
        }))
    } 
});
// eslint-disable-next-line no-undef
const uniqueOrders = [...new Set([].concat(...window.initialData.players.map((player) => player.order)))];
if (uniqueOrders.length > 1) {
    players.showColumn('order');
    if (uniqueOrders.length != window.initialData.players.length) {
        players.addSearch(
            { field: 'order', label: 'Batting Order', type: 'int', operator: 'between'
        });
    }
}

players.records = window.initialData.players.map(player =>({ 
    includeOver: !player.status, 
    includeUnder: !player.status,
    ...player,
    w2ui: { style: { [player.over == player.max ? 'over' : 'under']: player.max ? 'background: #e7f0fd;' : '' } }
}));
toolbar.get('sport').text = window.initialData.sport.toUpperCase();
toolbar.get('sport').selected = window.initialData.sport;
toolbar.get('sport').items = window.initialData.sports.map(sport => ({
    id: sport,
    text: sport,
}));
layout.assignToolbar('top', toolbar);

(() => {

    if (window.initialData.slates.length == 0) {
        toolbar.items.filter(item => item.id != 'sport').map(item => item.hidden = true);
        layout.html('main', `
        <div class="w2ui-centered" style="font-size: 16px; color: gray">
            No ${window.initialData.sport.toUpperCase()} Slate Data Found
        </div>`);
        return;
    }

    const slates = window.initialData.slates.map(slate => ({
        id: slate.id,
        text: `${new Date(slate.start).toLocaleTimeString('en-us', {  weekday: 'short', hour: 'numeric', minute: '2-digit' })}${slate.startTimeSuffix}`,
        checked: (slate.id == window.initialData.slate),
    }));

    toolbar.get('slate').text = slates.find(slate => slate.id == window.initialData.slate).text;
    toolbar.get('slate').selected = window.initialData.slate;
    toolbar.get('slate').items = slates;

    if (window.initialData.players.length == 0) {
        toolbar.items.filter(item => !['sport', 'slate'].includes(item.id)).map(item => item.hidden = true);
        layout.html('main', `
        <div class="w2ui-centered" style="font-size: 16px; color: gray">
            No Player Data Found
        </div>`);
        return;
    }

    layout.html('main', players);
})();

// initialization
layout.render('#app-main');

// eslint-disable-next-line no-undef
const positions = [...new Set(window.initialData.players.flatMap(player => [`${player.statAbbreviation}-O`, `${player.statAbbreviation}-U`]))];
// eslint-disable-next-line no-undef
console.log(positions.map(pos => ({ stat: `${window.initialData.players.find(player => pos.startsWith(player.statAbbreviation)).stat} ${pos.endsWith('-O') ? 'Over' : 'Under'}`, statAbrreviation: pos })));

const restrictionGridsColumns = [
    { field: 'variable', size: '200px', sortable: true },
    ...positions.map(pos => ({ field: pos, text: `<div class="rotate_text">${pos}</div>`, sortable: true, editable: { type: 'checkbox' }  })),
];

sameTeamRestriction.columns = restrictionGridsColumns;
sameTeamRestriction.records = positions.map((pos, i) => {
    return { 
        recid: pos,
        variable: pos,
        ...Object.assign({}, ...positions.map(pos2 => ({ [pos2]: false }))),
        w2ui: {
            style: Object.assign({}, ...Array(i).fill().map((x, idx) => ( { [ restrictionGridsColumns[idx+1].field]: 'visibility: hidden;' } )))
        }
    };
});

opposingTeamRestriction.columns = restrictionGridsColumns;
opposingTeamRestriction.records = positions.map((pos, i) => {
    const getCheck = (pos1, pos2) => {
        const dkOpposingTeamRestrictions = {
            // Strikeouts Thrown cannot exist with: Batter Strikeouts, Fantasy Points
            "SO-O": ["FPTS-O", "FPTS-U"],
            "SO-U": ["FPTS-O", "FPTS-U"],
            // Hits Against cannot exist with: Hits, Hits+Runs+RBIs, Total Bases (From hits), Fantasy Points
            "HA-O": ["H-O", "H-U", "H+R+RBI-O", "H+R+RBI-U", "Bases-O", "Bases-U", "FPTS-O", "FPTS-U"],
            "HA-U": ["H-O", "H-U", "H+R+RBI-O", "H+R+RBI-U", "Bases-O", "Bases-U", "FPTS-O", "FPTS-U"],
            // Earned Runs Allowed cannot exist with: Hits+Runs+RBIs, Fantasy Points, RBIs, Runs
            "ER-O": ["H+R+RBI-O", "H+R+RBI-U", "FPTS-O", "FPTS-U" /* @todo RBIs and Runs */],
            "ER-U": ["H+R+RBI-O", "H+R+RBI-U", "FPTS-O", "FPTS-U" /* @todo RBIs and Runs */],
            // Outs cannot exist with: Total Bases (From Hits), Hits, Fantasy Points, Hits+Runs+RBIs
            "O-O": ["Bases-O", "Bases-U", "H-O", "H-U", "FPTS-O", "FPTS-U", "H+R+RBI-O", "H+R+RBI-U"],
            "O-U": ["Bases-O", "Bases-U", "H-O", "H-U", "FPTS-O", "FPTS-U", "H+R+RBI-O", "H+R+RBI-U"],
            // @todo Walks Allowed cannot exist with: Batter Walks
        };
        return dkOpposingTeamRestrictions[pos1]?.includes(pos2) | dkOpposingTeamRestrictions[pos2]?.includes(pos1) | false;
    };
    return { 
        recid: pos,
        variable: pos,
        ...Object.assign({}, ...positions.map(pos2 => ({ [pos2]: getCheck(pos, pos2) }))),
        w2ui: { 
            style: Object.assign({}, ...Array(i).fill().map((x, idx) => ( { [restrictionGridsColumns[idx+1].field]: 'visibility: hidden;' } )))
        }
    };
});