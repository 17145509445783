import { w2base, query } from '../libs/w2ui/w2ui.es6.min.js';

class Lineups extends w2base {
    constructor(conf) {
        // custom code
        super(conf.name);
        this.name = conf.name;
        this.data = conf.data;
    }

    render(box=this.box) {
        let edata = this.trigger('render', { box });
        if (edata.cancelled) {
            return;
        }
        this.box = box;

        const generateTables = (dataArray) => {
            return dataArray.map(data => {
                // Destructure the data
                const { players, summary } = data;
            
                // Generate the player rows
                const playerRows = players.map(player => `
                <tr>
                    <td class="teamName">${player.team}</td>
                    <td>${player.order || ''}</td>
                    <td class="playerName">${player.playerName}</td>
                    <td class="pick">${player.pick} ${player.line.split?.(' (')[0] || player.line} ${player.stat}</td>
                    <td>${player.points.toFixed(2)}</td>
                </tr>
                `).join('');
            
                // Generate the summary row
                const summaryRow = `
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td style="border-right: none; text-align: right;"><strong>EV:</strong></td>
                    <td style="border-left: none;"><strong>${summary.totalPoints.toFixed(2)}%</strong></td>
                </tr>
                `;
            
                // Generate the complete table
                const tableHTML = `
                <table class="lineup">
                    <thead>
                    <tr>
                        <th>Team</th>
                        <th>Order</th>
                        <th>Player</th>
                        <th>Pick</th>
                        <th>Win%</th>
                    </tr>
                    </thead>
                    <tbody>
                    ${playerRows}
                    ${summaryRow}
                    </tbody>
                </table>
                `;
            
                return tableHTML;
            }).join('');
        };

        function calculateExpectedValue(payoutMultipliers) {
            // probabilityOfWinning is now an array of objects that contain the number of picks correct and the associated probability
            // e.g., [{numberOfPicksCorrect: 5, probability: 0.10}, {numberOfPicksCorrect: 4, probability: 0.20}]
        
            let totalExpectedValueLow = 0;
            let totalExpectedValueAverage = 0;
            let totalExpectedValueHigh = 0;
            let totalProbability = 0;
        
            // Loop through each tier
            payoutMultipliers.forEach(tier => {
                const {
                    lowPayoutMultiplier,
                    averagePayoutMultiplier,
                    highPayoutMultiplier,
                    probability
                } = tier;

                // Calculate expected values for each multiplier
                const expectedValueLow = (probability * (lowPayoutMultiplier - 1));
                const expectedValueAverage = (probability * (averagePayoutMultiplier - 1));
                const expectedValueHigh = (probability * (highPayoutMultiplier - 1));

                // Add the tier's expected values to the total
                totalExpectedValueLow += expectedValueLow;
                totalExpectedValueAverage += expectedValueAverage;
                totalExpectedValueHigh += expectedValueHigh;
                totalProbability += probability;
            });
        
            const probabilityOfLosing = 1 - totalProbability;
            // Return the total expected values in an object
            return {
                expectedValueLow: totalExpectedValueLow - probabilityOfLosing,
                expectedValueAverage: totalExpectedValueAverage - probabilityOfLosing,
                expectedValueHigh: totalExpectedValueHigh - probabilityOfLosing
            };
        }

        /**
         * Calculate the probability of exactly numberOfPicksCorrect picks winning in a pick set.
         * @param {Array} row - Array of objects where each object has a 'points' property
         * @param {number} numberOfPicksCorrect - Exact number of picks that need to be correct
         * @returns {number} - Probability of exactly numberOfPicksCorrect picks being correct
         */
        function calculateProbability(row, numberOfPicksCorrect) {
            // const n = row.length;

            // Helper function to calculate combinations
            function getCombinations(array, size) {
                function combine(input, length, start, combination) {
                    if (length === 0) {
                        result.push([...combination]);
                        return;
                    }
                    for (let i = start; i <= input.length - length; i++) {
                        combination.push(input[i]);
                        combine(input, length - 1, i + 1, combination);
                        combination.pop();
                    }
                }

                const result = [];
                combine(array, size, 0, []);
                return result;
            }

            // Helper function to calculate the probability of a specific combination
            function calculateCombinationProbability(combination, remaining) {
                const winProbability = combination.reduce((prob, { points }) => prob * (points / 100), 1);
                const loseProbability = remaining.reduce((prob, { points }) => prob * (1 - points / 100), 1);
                return winProbability * loseProbability;
            }

            // Calculate total probability for exactly 'numberOfPicksCorrect' correct picks
            let totalProbability = 0;

            const combinations = getCombinations(row, numberOfPicksCorrect);
            combinations.forEach(combination => {
                const remaining = row.filter(item => !combination.includes(item));
                totalProbability += calculateCombinationProbability(combination, remaining);
            });

            return totalProbability;
        }

        const exampleData = this.data.map(row => {
            const calculatedEv = calculateExpectedValue(window.initialData.payouts[this.data[0].length].map(payoutTier => ({
                ...payoutTier, probability: calculateProbability(row, payoutTier.numberOfPicksCorrect)
            })));
            const expectedValue = () => { 
                switch(window.app.w2ui.rulesForm.getValue('evCalcVariable')) {
                    case 'Min Payout':
                        return calculatedEv.expectedValueLow;
                    case 'Avg Est Payout':
                        return calculatedEv.expectedValueAverage;
                    case 'High Est Payout':
                        return calculatedEv.expectedValueHigh;
                    default:
                        return calculatedEv.expectedValueHigh;
                }
            };
            return {
                players: row,
                summary: { 
                    totalPoints: expectedValue() * 100
                },
            };
        });
          
        const tablesHTML = generateTables(exampleData);

        query(box).html(tablesHTML);
        // event's on complete loop
        edata.finish();
    }
}

export default Lineups;

// const getLineups = (name, data) => {
//     console.log(data);
//     const lineups = new w2base(name);
//     lineups.render = (box) => {
//         console.log(box);
//         let edata = lineups.trigger('render', { box });
//         if (edata.cancelled) {
//             return;
//         }
//         lineups.box = box;
//         query(box).html('<div style="background-color: lightgreen; width: 200px; border-radius: 3px; padding: 10px;">my component</div>');
//         // event's on complete loop
//         edata.finish();
//     };
//     return lineups;
// };

// export default getLineups;